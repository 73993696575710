@import './foundation_and_overrides';
@import 'utilities';
@import 'print_styles';
@import 'global/spinner';
@import 'foundation/header';
@import 'foundation/cards';
@import 'foundation/off-canvas';
@import 'global/sections';
@import 'global/cards';
@import 'global/lists';
@import 'global/percentage_circle';
@import 'global/logos';
@import 'global/modals';
@import 'global/weighingInfo';
@import 'global/modals';
@import 'global/button';

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

@media print and (orientation: portrait){
  body {
    margin: 3rem;
    widows: 2;
  }
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1;
}

//body {
//  background: radial-gradient(circle 1039px at 45.5% 27.72%, #f3f8e61c 0%, #8ee6e71c 60%, #1dc4e91c 100%);
//}

