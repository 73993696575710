@import '../settings';

.off-canvas {
  background: $white;
  border-top-left-radius: 40px;
  box-shadow: 0 3px 15px rgba($black, .2);
  height: 100%;
  max-width: 60%;
  min-width: 250px;
  padding-top: 3rem;
  text-align: left;
  transition: transform .5s ease-in-out;
  width: auto;
  z-index: 1000000;

  .close-button {
    background-color: $primary-color;
    border-radius: 50%;
    color: $white;
    margin: .5rem 1rem;
    padding: .3rem .8rem .5rem;
  }

  li {
    margin-bottom: 1px;

    &.locked {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // scss-lint:disable NestingDepth
      a {
        color: $light-gray;
        cursor: not-allowed;
      }
    }

    img {
      vertical-align: baseline;
    }
  }

  a {
    background: $white;
    color: $primary-color;
    display: inline-block;
    font-family: 'Lato', $body-font-family;
    font-weight: 700;
    letter-spacing: 1px;
    padding: .75rem;

    &:hover {
      color: darken(get-color(info), 5%);
    }
  }

  .link {
    display: block;

    a {
      color: $medium-gray;
      padding-bottom: .5rem;
      padding-top: .5rem;
    }
  }

  .sub-section-title {
    border-bottom: 2px solid $light-gray;
    color: $medium-gray;
    padding: .5rem;
  }
}
