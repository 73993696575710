@import '../settings';

.card {
  .card-section {
    display: flex;
    flex-direction: column;
  }

  .card-divider {
    background: none;
  }
}
