@import './settings';

.white-bg-color {
  background-color: $white;
}

.white-color {
  color: $white;
 }

.black-color {
  color: $black;
}

.notes-lines {
  border-bottom: 1px solid $black;
  height: 5px;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.height-10 {
  height: 10%;
}

.height-50-px {
  height: 50px;
}

.figure-style {
  height: 15px;
  margin-right: .5rem;
  width: 15px;
}

@each $name, $color in $button-palette {
  .#{$name}-color {
    color: get-color(#{$name});
  }
}

// scss-lint:disable ImportantRule
.success-color {
  color: $success-color !important;
}

.teal-color {
  color: $teal;
}

.gradient {
  background: linear-gradient(180deg, $white 0%, lighten(get-color(info), 55%) 100%);
}

.full-viewport-height {
  height: 100vh;
}

.quotes {
  color: $white;
  font-family: 'Lato', $body-font-family;
  font-size: 27px;
  font-style: italic;
  font-weight: 700;

  span {
    font-weight: 700;
  }
}

.all-rounded-borders {
  border-radius: 40px;
}

details {
  summary {
    cursor: pointer;

    > * {
      display: inline;
    }
  }
}

.benefits-text {
  color: $benefits-color;
  font-weight: bold;
}

.risks-text {
  color: $risks-color;
  font-weight: bold;
}

.subsequent-care-text {
  color: $subsequent-care-color;
  font-weight: bold;
}

.scroll-to-top-button {
  bottom: 20px;
  display: block;
  position: fixed;
  right: 30px;
  z-index: 99;
}

.tooltip-content {
  border-bottom: 1px solid $black;
  cursor: pointer;
  font-weight: bold;
}

.react-tooltip-clickable-link {
  background-color: $white !important;
  border: 1px solid $black !important;
  opacity: 1 !important;
  pointer-events: auto !important;

  a {
    text-decoration: underline !important;
  }

  &:hover {
    visibility: visible !important;
  }
}


table {
  td {
    border: 1px solid $black;
  }
}
