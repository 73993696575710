@import '../settings';

.crimson-list {
  border-right: 2px solid $medium-gray;
  counter-reset: item;
  font-family: 'Crimson Pro', $body-font-family;
  font-size: 24px;
  list-style: none;
  margin: 0 20px 0 0;

  li {
    counter-increment: item;
    margin-bottom: 5px;
  }

  li::before {
    background: $success-color;
    border: 2px solid $white;
    border-radius: 50%;
    box-shadow: 0 0 8px 1px $light-gray;
    color: $white;
    content: counter(item);
    display: inline-block;
    font-size: 18px;
    margin-right: 10px;
    text-align: center;
    width: 32px;
  }

}
