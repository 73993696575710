@import '../../stylesheets/settings';

h1 {
  font-family: 'Lato', $body-font-family;
  font-weight: 700;
}

h2 {
  font-family: 'Crimson Pro', $body-font-family;
  letter-spacing: -1px;
}

h3 {
  font-family: 'Lato', $body-font-family;
  font-weight: 700;
  letter-spacing: 1px;
}

h4 {
  font-family: 'Lato', $body-font-family;
  font-weight: 700;
}

p {
  font-family: 'Lato', $body-font-family;
  font-weight: 400;
}

span {
  font-family: 'Lato', $body-font-family;
}

.lead {
  font-family: 'Crimson Pro';
}

.navigation-logo {
  max-width: 40%;
}
