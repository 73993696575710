@import './../settings';

.percentage-circle {
  align-items: center;
  border: 2px solid $white;
  box-shadow: 0 1px 10px 0 $light-gray;
  display: flex;
  height: 100px;
  justify-content: center;
  margin: auto;
  width: 100px;

  .inside-circle {
    align-content: center;
    align-items: center;
    background-color: $white;
    display: flex;
    flex-direction: column;
    height: 75px;
    justify-content: center;
    width: 75px;
  }

  p {
    //color: $white;
    margin-bottom: 0;
  }
}

.bg-color-blue {
  background-color: $benefits-color;
}

.bg-color-red {
  background-color: $risks-color;
}

.bg-color-purple {
  background-color: $subsequent-care-color;
}
