@import '../settings';

section {
  padding: $global-padding * 2;

  &.gradient {
    background: linear-gradient(180deg, $white 0%, lighten(get-color(info), 55%) 100%);
  }

  &.alt {
    background: lighten($primary-color, 50%);
  }

  &.dark {
    background: darken(get-color(info), 20%);
    color: lighten($primary-color, 40%);

    a {
      color: lighten($anchor-color, 10%);
    }

    &.border-right {
      border-right: 10px solid $teal;
    }
  }

  &.top-rounded-borders {
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    box-shadow: 0 -8px 6px -6px $light-gray;
  }

  &.all-rounded-borders {
    border-radius: 40px;
    box-shadow: 0 -8px 6px -6px $light-gray;
  }
}

