@import './../settings';

.symbols {
  color: lighten($success-color, 1%);
}


.dark {
  background: darken(get-color(info), 20%);

  h2,
  h3 {
    color: lighten($primary-color, 40%);
  }
}

.round-borders {
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.icon {

  img {
    height: 1.5rem;
  }
}

.callout {

  &.box {
    background-color: $white;
    border-radius: 40px;
  }

  &.primary {
    border-bottom: 5px solid $primary-color;
  }

  &.alert {
    border-bottom: 5px solid $alert-color;
  }

  &.info {
    border-bottom: 5px solid get-color(info);
  }
}

.margin-auto {
  margin: auto;
}
