@import '../settings';

.modal {
  align-items: center;
  background-color: rgba($black, .5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

