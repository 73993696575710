@import './settings';

// scss-lint:disable ImportantRule
@media  print {
  body {
    background: $primary-color;
    font-size: 13px !important;

    h1 {
      font-size: 22px !important;
    }

    h2 {
      font-size: 20px !important;
    }

    h3 {
      font-size: 18px !important;
    }

    h4 {
      font-size: 16px !important;
    }

    p,
    span {
      font-size: 13px !important;
    }

    .img-print-styles {
      width: 50% !important;
    }

    .my-health-factors-print-styles {
      margin: 0 20px !important;
      padding: 0 !important;
    }

    .priorities-icon {
      height: 20px;
      width: auto;
    }

    .table-print-styles {
      td {
        padding: 5px !important;
      }
    }

    .percentage-circle {
      align-items: center;
      border: 2px solid $white;
      box-shadow: 0 1px 10px 0 $light-gray;
      display: flex;
      height: 30px;
      justify-content: center;
      margin: auto;
      width: 100px;

      .inside-circle {
        align-content: center;
        align-items: center;
        background-color: $white;
        display: flex;
        flex-direction: column;
        height: 75px;
        justify-content: center;
        width: 75px;
      }

      p {
        //color: $white;
        margin-bottom: 0;
      }
    }
  }

  main {
    section {
      padding: 1rem;
    }
  }

  section,
  div {
    border: 0 !important;
    padding: 0 !important;
  }

  .print-paddings {
    // scss-lint:disable ImportantRule
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .page-break-before {
    page-break-before: always;
  }

  .avoid-page-break-inside {
    page-break-inside: avoid;
  }

  .page-break-after {
    page-break-after: always;
  }

}
